import { useMutation } from "@tanstack/react-query";
import API_URL from "Constants";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";

function DeleteRoleModal({ show, setShowModal, selectedRole, refetchRoles }) {
  const deleteRole = useMutation({
    mutationFn: () => {
      return axios.delete(
        API_URL + `/Roles/DeleteRole/${selectedRole?.roleId}`
      );
    },
    onSuccess: () => {
      refetchRoles();
      setShowModal(false);
    },
    onError: (error) => {
      alert(error?.message);
    },
  });

  const handleDeleteRole = () => {
    deleteRole.mutate();
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>Are You Sure You Want To Delete this Role?</Modal.Body>
      <Modal.Footer>
        <button className="btn btn-danger" onClick={() => handleDeleteRole()}>
          Delete
        </button>
        <button className="btn btn-dark" onClick={() => setShowModal(false)}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteRoleModal;
