import { useState, useEffect } from "react";
import { Sidebar } from "components";
import {
  AddUsersModal,
  DeleteUsersModal,
  EditUserModal,
} from "components/Users";
import { useMutation } from "@tanstack/react-query";
import { useFetchAllUsers } from "hooks/users";
import API_URL from "Constants";
import axios from "axios";
import moment from "moment/moment";
import "./Dashboard.scss";
import { isEmpty } from "lodash";

function Users() {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const { data: allUsers, refetch: refetchUsers } = useFetchAllUsers();

  useEffect(() => {
    refetchUsers();
  }, []);

  console.log("allUsers: ", allUsers?.data);

  const createUser = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/User/CreateUser`, fields);
    },
    onSuccess: () => {
      refetchUsers();
      setShowModal(false);
    },
    onError: (error) => {
      alert(error?.message);
    },
  });

  const editUser = useMutation({
    mutationFn: (fields) => {
      return axios.put(API_URL + `/User/UpdateUser/${fields?.userId}`, fields);
    },
    onSuccess: (data) => {
      refetchUsers();
      setShowEditModal(false);
    },
    onError: (error) => {
      alert(error?.message);
    },
  });

  const handleAddUser = (fields) => {
    createUser.mutate(fields);
  };

  const handleEditUser = (fields) => {
    console.log("fields: ", fields);
    editUser.mutate(fields);
  };

  return (
    <div>
      <Sidebar />
      <div className="container" id="outer-container">
        <AddUsersModal
          show={showModal}
          setShowModal={setShowModal}
          handleAddUser={handleAddUser}
        />
        <DeleteUsersModal
          show={showDeleteModal}
          setShowModal={setShowDeleteModal}
          selectedUser={selectedUser}
          refetchUsers={refetchUsers}
        />
        <EditUserModal
          show={showEditModal}
          setShowModal={setShowEditModal}
          selectedUser={selectedUser}
          handleEditUser={handleEditUser}
        />
        <div className="d-flex align-items-center justify-content-center">
          <h1>Users</h1>
        </div>
        <div className="px-3">
          <table className="table users-table">
            <thead>
              <tr>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Email</th>
                <th scope="col">Mobile</th>
                <th scope="col">Date of Birth</th>
                <th scope="col">Division</th>
                <th scope="col">Sector</th>
                <th scope="col">Unit</th>
                <th scope="col">Office</th>
                <th scope="col">Role</th>
                <th scope="col">Created At</th>
                <th scope="col">
                  <i
                    onClick={() => setShowModal(true)}
                    className="fa-solid fa-user-plus"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {allUsers?.data?.map((user) => (
                <tr key={user?.userId}>
                  <td>{user?.firstName}</td>
                  <td>{user?.lastName}</td>
                  <td>{user?.email}</td>
                  <td>{user?.mobile}</td>
                  <td>
                    {user?.birthDate &&
                      moment(user?.birthDate).format("MMM Do YY")}
                  </td>
                  <td>{user?.division}</td>
                  <td>{user?.sector}</td>
                  <td>{user?.unit}</td>
                  <td>{user?.office}</td>
                  <td>{user?.userRoleName}</td>
                  <td>
                    {user?.createdTime &&
                      moment(user?.createdTime).format("MMM Do YY")}
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-trash"
                      onClick={() => (
                        setShowDeleteModal(true), setSelectedUser(user)
                      )}
                    />
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-pen-to-square"
                      onClick={() => (
                        setShowEditModal(true), setSelectedUser(user)
                      )}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Users;
