import { useState, useEffect } from "react";
import { Sidebar } from "components";
import {
  AddHighlightModal,
  DeleteHighlightModal,
  EditHighlightModal,
} from "components/Highlights";
import { useMutation } from "@tanstack/react-query";
import API_URL from "Constants";
import { useFetchAllHighlights } from "hooks/highlights";
import { isEmpty } from "lodash";
import axios from "axios";
import "./Highlights.scss";

function Highlights() {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedHighlight, setSelectedHighlight] = useState();
  const { data: highlights, refetch: refetchHighlights } =
    useFetchAllHighlights();

  useEffect(() => {
    refetchHighlights();
  }, []);

  const createHighlight = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/api/Highlight/Create`, fields);
    },
    onSuccess: () => {
      setShowModal(false);
      refetchHighlights();
    },
    onError: (error) => {
      alert(error?.message);
    },
  });

  const editHighlight = useMutation({
    mutationFn: (fields) => {
      return axios.put(
        API_URL + `/api/Highlight/Update/${fields?.id}`,
        fields?.fields
      );
    },
    onSuccess: () => {
      setShowEditModal(false);
      refetchHighlights();
    },
    onError: (error) => {
      alert(error?.message);
    },
  });

  const handleAddHighlight = (fields) => {
    if (!isEmpty(fields.entries().next().value)) {
      createHighlight.mutate(fields);
    }
  };

  const handleEditHighlight = (fields, id) => {
    editHighlight.mutate({ fields, id });
  };

  return (
    <div>
      <Sidebar />
      <AddHighlightModal
        show={showModal}
        setShowModal={setShowModal}
        handleAddHighlight={handleAddHighlight}
      />
      <DeleteHighlightModal
        show={showDeleteModal}
        setShowModal={setShowDeleteModal}
        selectedHighlight={selectedHighlight}
        refetchHighlights={refetchHighlights}
      />
      <EditHighlightModal
        show={showEditModal}
        setShowModal={setShowEditModal}
        selectedHighlight={selectedHighlight}
        handleEditHighlight={handleEditHighlight}
      />
      <div className="container" id="outer-container">
        <div className="d-flex align-items-center justify-content-center">
          <h1>Highlights</h1>
        </div>
        <div className="px-3">
          <table className="table users-table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Image</th>
                <th scope="col">Image Type</th>
                <th scope="col">Title</th>
                <th scope="col">Description</th>
                <th scope="col">URL</th>
                <th scope="col">
                  <i
                    onClick={() => (setShowModal(true), setSelectedHighlight())}
                    className="fa-solid fa-plus"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {highlights?.data?.map((highlight, index) => (
                <tr key={highlight?.highlightId}>
                  <td>{index + 1}</td>
                  <td>
                    {!isEmpty(highlight?.image) ? (
                      <img
                        height={50}
                        width={50}
                        src={`data:image/jpeg;base64,${highlight?.image}`}
                      />
                    ) : (
                      <>N / A</>
                    )}
                  </td>
                  <td>{highlight?.imageType}</td>
                  <td>{highlight?.title}</td>
                  <td>{highlight?.description}</td>
                  <td>{highlight?.url}</td>
                  <td>
                    <i
                      className="fa-solid fa-trash"
                      onClick={() => (
                        setShowDeleteModal(true),
                        setSelectedHighlight(highlight)
                      )}
                    />
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-pen-to-square"
                      onClick={() => (
                        setShowEditModal(true), setSelectedHighlight(highlight)
                      )}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Highlights;
