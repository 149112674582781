import { useState } from "react";
import API_URL from "Constants";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import "./Login.scss";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const login = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/auth/login`, fields);
    },
    onSuccess: async (data) => {
      localStorage.setItem("token", JSON.stringify(data.data.token));
      window.location.href = "/dashboard";
    },
    onError: (error) => {
      console.log("error: ", error);
    },
  });

  const validateForm = () => {
    return username.length > 0 && password.length > 0;
  };

  const handleSubmit = async () => {
    login.mutate({
      username: username,
      password: password,
    });
  };

  return (
    <div className="main">
      <div className="h-100 d-flex align-items-center justify-content-center">
        <div className="w-sm-100 w-md-50 m-auto">
          <div className="text-center">
            <p className="title-welcome mb-0">Welcome to</p>
            <span className="title-support-hub">
              <span className="title-support">SUPPORT</span>HUB
            </span>
          </div>
          <div className="form-group my-3">
            <label>Username</label>
            <input
              type="text"
              placeholder="Username"
              value={username}
              className="form-control"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label>Password</label>
            <input
              value={password}
              type="password"
              placeholder="Password"
              className="form-control"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            className="btn btn-danger mt-3 submit-btn"
            disabled={!validateForm()}
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login;
