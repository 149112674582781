import { useState } from "react";
import { Modal } from "react-bootstrap";

function AddHighlightModal({ show, setShowModal, handleAddHighlight }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageType, setImageType] = useState("");
  const [image, setImage] = useState();
  const [url, setUrl] = useState("");

  const addHighlight = () => {
    var formData = new FormData();
    formData.append("Title", title);
    formData.append("Description", description);
    formData.append("ImageType", imageType);
    formData.append("Url", url);
    formData.append("FileToUpload", image);
    handleAddHighlight(formData);
    setTitle("");
    setDescription("");
    setImageType("");
    setImage();
    setUrl("");
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Highlight
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row py-2">
          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group mt-2">
            <label>Description</label>
            <textarea
              className="form-control"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            ></textarea>
          </div>
          <div className="form-group mt-2 col-md-6">
            <label>Image Type</label>
            <input
              type="text"
              className="form-control"
              value={imageType}
              onChange={(e) => setImageType(e.target.value)}
            />
          </div>
          <div className="form-group mt-2 col-md-6">
            <label>URL</label>
            <input
              type="text"
              className="form-control"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
          <div className="form-group mt-2 col-md-6">
            <label>Image</label>
            <input
              type="file"
              className="form-control-file"
              onChange={(e) => setImage(e.target.files[0])}
              accept="image/*"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={() => addHighlight()}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddHighlightModal;
