import { useMutation } from "@tanstack/react-query";
import API_URL from "Constants";
import axios from "axios";
import { Modal } from "react-bootstrap";

function DeleteHighlightModal({
  show,
  setShowModal,
  selectedHighlight,
  refetchHighlights,
}) {
  const deleteHighlight = useMutation({
    mutationFn: () => {
      return axios.delete(
        API_URL + `/api/Highlight/Delete/${selectedHighlight?.highlightId}`
      );
    },
    onSuccess: () => {
      refetchHighlights();
      setShowModal(false);
    },
    onError: (error) => {
      alert(error?.message);
    },
  });

  const handleDeleteHighlight = () => {
    deleteHighlight.mutate();
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Highlight
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>Are You Sure You Want To Delete this Highlight?</Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-danger"
          onClick={() => handleDeleteHighlight()}
        >
          Delete
        </button>
        <button className="btn btn-dark" onClick={() => setShowModal(false)}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteHighlightModal;
