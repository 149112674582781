import { useState, useEffect } from "react";
import { Sidebar } from "components";
import { AddRoleModal, DeleteRoleModal, EditRoleModal } from "components/Roles";
import { useMutation } from "@tanstack/react-query";
import { useFetchAllRoles } from "hooks/roles";
import API_URL from "Constants";
import { isEmpty } from "lodash";
import axios from "axios";
import "./Roles.scss";

function Roles() {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const { data: roles, refetch: refetchRoles } = useFetchAllRoles();

  useEffect(() => {
    refetchRoles();
  }, []);

  const createRole = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/Roles/CreateRole`, fields);
    },
    onSuccess: (res) => {
      handleAddCategoriesToRole(JSON.parse(res.config.data), res.data.roleId);
      setShowModal(false);
      setShowEditModal(false);
      refetchRoles();
    },
    onError: (error) => {
      alert(error?.message);
    },
  });

  const addCategoriesToRole = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/Roles/CreateBulkRoleDetails`, fields);
    },
    onSuccess: () => {
      refetchRoles();
    },
    onError: (error) => {
      alert(error?.message);
    },
  });

  const editRole = useMutation({
    mutationFn: (fields) => {
      return axios.put(API_URL + `/Roles/UpdateRole/${fields?.roleId}`, fields);
    },
    onSuccess: (res) => {
      handleAddCategoriesToRole(
        JSON.parse(res.config.data),
        selectedRole.roleId
      );
      setShowModal(false);
      setShowEditModal(false);
    },
    onError: (error) => {
      alert(error?.message);
    },
  });

  const handleAddRole = (fields) => {
    if (!isEmpty(fields.roleName)) {
      createRole.mutate(fields);
    }
  };

  const handleEditRole = (fields) => {
    if (!isEmpty(fields.roleName)) {
      editRole.mutate(fields);
    }
  };

  const handleAddCategoriesToRole = (fields, roleId) => {
    var roleCategories = [];
    for (var i = 0; i < fields?.categoryIds.length; i++) {
      roleCategories.push(fields.categoryIds[i]);
    }
    if (!isEmpty(roleCategories)) {
      addCategoriesToRole.mutate([
        {
          roleId: roleId,
          categoryId: roleCategories.join(", "),
        },
      ]);
    }
  };

  return (
    <div>
      <Sidebar />
      <div className="container" id="outer-container">
        <AddRoleModal
          show={showModal}
          setShowModal={setShowModal}
          handleAddRole={handleAddRole}
        />
        <DeleteRoleModal
          show={showDeleteModal}
          setShowModal={setShowDeleteModal}
          selectedRole={selectedRole}
          refetchRoles={refetchRoles}
        />
        <EditRoleModal
          show={showEditModal}
          setShowModal={setShowEditModal}
          selectedRole={selectedRole}
          handleEditRole={handleEditRole}
        />
        <div className="d-flex align-items-center justify-content-center">
          <h1>Roles</h1>
        </div>
        <div className="px-3">
          <table className="table users-table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Categories</th>
                <th scope="col">
                  <i
                    onClick={() => (setShowModal(true), setSelectedRole())}
                    className="fa-solid fa-plus"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {roles?.data?.map((role, index) => (
                <tr key={role?.roleId}>
                  <td>{index + 1}</td>
                  <td>{role?.roleName}</td>
                  <td>
                    {role?.categories
                      .map(({ categoryName }) => categoryName)
                      .join(", ")}
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-trash"
                      onClick={() => (
                        setShowDeleteModal(true), setSelectedRole(role)
                      )}
                    />
                  </td>
                  <td>
                    <i
                      className="fa-solid fa-pen-to-square"
                      onClick={() => (
                        setShowEditModal(true), setSelectedRole(role)
                      )}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Roles;
