import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { isEmpty } from "lodash";
import { useFetchAllCategories } from "hooks/categories";

function EditRoleModal({ show, setShowModal, handleEditRole, selectedRole }) {
  const [name, setName] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [load, setLoad] = useState(false);
  const { data: categories, refetch: refetchCategories } =
    useFetchAllCategories();

  useEffect(() => {
    setSelectedCategories();
    refetchCategories();
  }, []);

  useEffect(() => {
    setSelectedCategories([]);
    if (!isEmpty(selectedRole?.categories[0]?.categoryName)) {
      for (let i = 0; i < selectedRole?.categories?.length; i++) {
        setSelectedCategories((selectedCategories) => [
          ...selectedCategories,
          selectedRole.categories[i].categoryId,
        ]);
      }
      setLoad(!load);
    }
  }, [selectedRole]);

  const renderTree = () => {
    if (!isEmpty(categories?.data)) {
      return (
        <>
          {categories?.data?.map(
            (category) =>
              0 == category?.parentId && (
                <div key={category.categoryId}>
                  <Form.Check
                    type="checkbox"
                    id={category.categoryId}
                    value={category.categoryId}
                    label={category.categoryName}
                    onChange={(e) => handleChange(e)}
                    checked={
                      selectedCategories.some((e) => e === category.categoryId)
                        ? true
                        : false
                    }
                  />
                  {renderFirstChildren(category?.categoryId)}
                </div>
              )
          )}
        </>
      );
    }
  };

  const renderFirstChildren = (id) => {
    return (
      <>
        {categories.data.map(
          (category) =>
            id == category?.parentId && (
              <div className="ms-3" key={category.categoryId}>
                <Form.Check
                  type="checkbox"
                  id={category.categoryId}
                  label={category.categoryName}
                  value={category.categoryId}
                  onChange={(e) => handleChange(e)}
                  checked={
                    selectedCategories.some((e) => e === category.categoryId)
                      ? true
                      : false
                  }
                />
                {renderSecondChildren(category?.categoryId)}
              </div>
            )
        )}
      </>
    );
  };

  const renderSecondChildren = (id) => {
    return (
      <>
        {categories.data.map(
          (category) =>
            id == category?.parentId && (
              <div className="ms-3" key={category.categoryId}>
                <Form.Check
                  type="checkbox"
                  id={category.categoryId}
                  label={category.categoryName}
                  value={category.categoryId}
                  onChange={(e) => handleChange(e)}
                  checked={
                    selectedCategories.some((e) => e === category.categoryId)
                      ? true
                      : false
                  }
                />
              </div>
            )
        )}
      </>
    );
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      setSelectedCategories((selectedCategories) => [
        ...selectedCategories,
        parseInt(e.target.value),
      ]);
      return;
    }
    setSelectedCategories(
      selectedCategories.filter(
        (selectedCategories) => selectedCategories !== parseInt(e.target.value)
      )
    );
  };

  useEffect(() => {
    if (!isEmpty(selectedRole)) {
      setName(!isEmpty(selectedRole?.roleName) ? selectedRole?.roleName : "");
    }
  }, [selectedRole]);

  const editRole = () => {
    handleEditRole({
      roleId: selectedRole.roleId,
      roleName: name,
      categoryIds: selectedCategories,
    });
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row py-2">
          <div className="form-group col-md-6">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <Form>{renderTree()}</Form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => editRole()} className="btn btn-primary">
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditRoleModal;
