import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import API_URL from "Constants";

const fetchAllCategories = async () => {
  return await axios.get(API_URL + `/Category/GetAllCategories`);
};

export const useFetchAllCategories = () => {
  return useQuery({
    queryKey: ["categories"],
    queryFn: () => fetchAllCategories(),
    enabled: false,
  });
};
