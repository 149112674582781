import { slide as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import "./Sidebar.scss";

function Sidebar() {
  const handleLogoutUser = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  return (
    <Menu>
      <NavLink className="menu-item" to="/dashboard">
        Users
      </NavLink>
      <NavLink className="menu-item" to="/dashboard/roles">
        Roles
      </NavLink>
      <NavLink className="menu-item" to="/dashboard/highlights">
        Highlights
      </NavLink>
    </Menu>
  );
}

export default Sidebar;
