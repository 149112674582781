import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useFetchAllCategories } from "hooks/categories";
import { isEmpty } from "lodash";

function AddRoleModal({ show, setShowModal, handleAddRole }) {
  const [name, setName] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const { data: categories, refetch: refetchCategories } =
    useFetchAllCategories();

  useEffect(() => {
    refetchCategories();
  }, []);

  const addRole = () => {
    handleAddRole({
      roleName: name,
      categoryIds: selectedCategories,
    });
    setSelectedCategories([]);
    setName("");
  };

  const renderTree = () => {
    if (!isEmpty(categories?.data)) {
      return (
        <>
          {categories.data.map(
            (category) =>
              0 == category?.parentId && (
                <div key={category.categoryId}>
                  <Form.Check
                    type="checkbox"
                    id={category.categoryId}
                    value={category.categoryId}
                    label={category.categoryName}
                    onChange={(e) => handleChange(e)}
                  />
                  {renderFirstChildren(category?.categoryId)}
                </div>
              )
          )}
        </>
      );
    }
  };

  const renderFirstChildren = (id) => {
    return (
      <>
        {categories.data.map(
          (category) =>
            id == category?.parentId && (
              <div className="ms-3" key={category.categoryId}>
                <Form.Check
                  type="checkbox"
                  id={category.categoryId}
                  label={category.categoryName}
                  value={category.categoryId}
                  onChange={(e) => handleChange(e)}
                />
                {renderSecondChildren(category?.categoryId)}
              </div>
            )
        )}
      </>
    );
  };

  const renderSecondChildren = (id) => {
    return (
      <>
        {categories.data.map(
          (category) =>
            id == category?.parentId && (
              <div className="ms-3" key={category.categoryId}>
                <Form.Check
                  type="checkbox"
                  id={category.categoryId}
                  label={category.categoryName}
                  value={category.categoryId}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            )
        )}
      </>
    );
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      setSelectedCategories((selectedCategories) => [
        ...selectedCategories,
        e.target.value,
      ]);
      return;
    }
    setSelectedCategories(
      selectedCategories.filter(
        (selectedCategories) => selectedCategories !== e.target.value
      )
    );
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row py-2">
          <div className="form-group col-md-6">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <Form>{renderTree()}</Form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary" onClick={() => addRole()}>
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddRoleModal;
