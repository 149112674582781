import { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { isEmpty } from "lodash";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useFetchAllRoles } from "hooks/roles";

function EditUserModal({ show, setShowModal, handleEditUser, selectedUser }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState();
  const [division, setDivision] = useState("");
  const [sector, setSector] = useState("");
  const [unit, setUnit] = useState("");
  const [office, setOffice] = useState("");
  const [userType, setUserType] = useState("");
  const [nfcid, setNfcid] = useState("");
  const [userId, setUserId] = useState("");
  const [userRoleId, setUserRoleId] = useState(1);
  const { data: roles, refetch: refetchRoles } = useFetchAllRoles();

  useEffect(() => {
    refetchRoles();
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedUser)) {
      setFirstName(
        !isEmpty(selectedUser?.firstName) ? selectedUser?.firstName : ""
      );
      setLastName(
        !isEmpty(selectedUser?.lastName) ? selectedUser?.lastName : ""
      );
      setEmail(!isEmpty(selectedUser?.email) ? selectedUser?.email : "");
      setMobile(!isEmpty(selectedUser?.mobile) ? selectedUser?.mobile : "");
      setUsername(
        !isEmpty(selectedUser?.username) ? selectedUser?.username : ""
      );
      setPassword(
        !isEmpty(selectedUser?.password) ? selectedUser?.password : ""
      );
      setDateOfBirth(
        !isEmpty(selectedUser?.dateOfBirth)
          ? new Date(moment(selectedUser?.dateOfBirth))
          : new Date()
      );
      setDivision(
        !isEmpty(selectedUser?.division) ? selectedUser?.division : ""
      );
      setSector(!isEmpty(selectedUser?.sector) ? selectedUser?.sector : "");
      setUnit(!isEmpty(selectedUser?.unit) ? selectedUser?.unit : "");
      setOffice(!isEmpty(selectedUser?.office) ? selectedUser?.office : "");
      setUserType(
        !isEmpty(selectedUser?.userType) ? selectedUser?.userType : ""
      );
      setNfcid(!isEmpty(selectedUser?.nfcid) ? selectedUser?.nfcid : "");
      setUserId(selectedUser?.userId);
      setUserRoleId(
        !isEmpty(selectedUser?.userRoleId) ? selectedUser?.userRoleId : 0
      );
    }
  }, [selectedUser]);

  const editUser = () => {
    handleEditUser({
      userId: userId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobile: mobile,
      birthDate: dateOfBirth,
      division: division,
      sector: sector,
      unit: unit,
      office: office,
      status: true,
      nfcid: nfcid,
      roleId: parseInt(userRoleId),
    });
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row py-2">
          <div className="form-group col-md-6">
            <label>First Name</label>
            <input
              type="text"
              className="form-control"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="row py-2">
          <div className="form-group col-md-6">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label>Date of Birth</label>
            <DatePicker
              selected={dateOfBirth}
              value={dateOfBirth}
              onChange={(date) => setDateOfBirth(date)}
            />
          </div>
        </div>
        <div className="row py-2">
          <div className="form-group col-md-6">
            <label>Mobile</label>
            <input
              type="text"
              className="form-control"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label>Username</label>
            <input
              type="text"
              className="form-control"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
        </div>
        <div className="row py-2">
          <div className="form-group col-md-6">
            <label>Password</label>
            <input
              type="text"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label>User Type</label>
            <input
              type="text"
              className="form-control"
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
            />
          </div>
        </div>
        <div className="row py-2">
          <div className="form-group col-md-6">
            <label>Division</label>
            <input
              type="text"
              className="form-control"
              value={division}
              onChange={(e) => setDivision(e.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label>Sector</label>
            <input
              type="text"
              className="form-control"
              value={sector}
              onChange={(e) => setSector(e.target.value)}
            />
          </div>
        </div>
        <div className="row py-2">
          <div className="form-group col-md-3">
            <label>Unit</label>
            <input
              type="text"
              className="form-control"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            />
          </div>
          <div className="form-group col-md-3">
            <label>Office</label>
            <input
              type="text"
              className="form-control"
              value={office}
              onChange={(e) => setOffice(e.target.value)}
            />
          </div>
          <div className="form-group col-md-3">
            <label>NFCID</label>
            <input
              type="text"
              className="form-control"
              value={nfcid}
              onChange={(e) => setNfcid(e.target.value)}
            />
          </div>
          <div className="form-group col-md-3">
            <label>Role</label>
            <Form.Select onChange={(e) => setUserRoleId(e.target.value)}>
              {roles?.data?.map((role) => (
                <option value={role?.roleId} key={role?.roleId}>
                  {role?.roleName}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => editUser()} className="btn btn-primary">
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditUserModal;
