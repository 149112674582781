import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import API_URL from "Constants";

const fetchAllUsers = async () => {
  return await axios.get(API_URL + `/User/GetAllUsers`);
};

export const useFetchAllUsers = () => {
  return useQuery({
    queryKey: ["users"],
    queryFn: () => fetchAllUsers(),
    enabled: false,
  });
};
