import { useMutation } from "@tanstack/react-query";
import API_URL from "Constants";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";

function DeleteUsersModal({ show, setShowModal, selectedUser, refetchUsers }) {
  const deleteUser = useMutation({
    mutationFn: () => {
      return axios.delete(API_URL + `/User/DeleteUser/${selectedUser?.userId}`);
    },
    onSuccess: () => {
      refetchUsers();
      setShowModal(false);
    },
    onError: (error) => {
      alert(error?.message);
    },
  });

  const handleDeleteUser = () => {
    deleteUser.mutate();
  };

  return (
    <Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>Are You Sure You Want To Delete this User?</Modal.Body>
      <Modal.Footer>
        <button className="btn btn-danger" onClick={() => handleDeleteUser()}>
          Delete
        </button>
        <button className="btn btn-dark" onClick={() => setShowModal(false)}>
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteUsersModal;
