import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import API_URL from "Constants";

const fetchAllRoles = async () => {
  return await axios.get(API_URL + `/Roles/GetFullRolesCategoryData`);
};

export const useFetchAllRoles = () => {
  return useQuery({
    queryKey: ["roles"],
    queryFn: () => fetchAllRoles(),
    enabled: false,
  });
};
