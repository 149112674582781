import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import API_URL from "Constants";

const fetchAllHighlights = async () => {
  return await axios.get(API_URL + `/api/Highlight/GetAll`);
};

export const useFetchAllHighlights = () => {
  return useQuery({
    queryKey: ["highlights"],
    queryFn: () => fetchAllHighlights(),
    enabled: false,
  });
};
